import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GetGatheringsForHomeDocument from "src/api/graphql/__generated__/documents/GetGatheringsForHomeDocument";
import native from "src/constants/native";
import useAdaptedFocusEffect from "src/hooks/useAdaptedFocusEffect";
import useStabilizedCallback from "src/hooks/useStabilizedCallback";
import useStateRef from "src/hooks/useStateRef";
import checkShouldRefresh from "../helpers/checkShouldRefresh";

const LIMIT_FACTOR = 12;
const key = "HomeGatherings";
const useHomeGatherings = ({ numColumns }: { numColumns: number }) => {
	const { data, fetchMore, refetch, loading } = useQuery(GetGatheringsForHomeDocument, {
		fetchPolicy: "cache-first",
		variables: {
			input: {
				limit: LIMIT_FACTOR * numColumns,
				key,
			},
		},
		returnPartialData: true,
	});

	const didInitialRefresh = useRef<boolean>(false);
	useAdaptedFocusEffect(
		useCallback(() => {
			if (!didInitialRefresh.current) {
				didInitialRefresh.current = true;
				checkShouldRefresh().then((shouldRefresh) => {
					if (!shouldRefresh) return;
					refetch({
						input: {
							limit: LIMIT_FACTOR * numColumns,
							key,
						},
					});
				});
			}
			if (native) {
				return () => {
					didInitialRefresh.current = false;
				};
			}
			return;
		}, [numColumns, refetch]),
	);

	const cursorRef = useRef<string | null | undefined>(data?.getGatherings?.cursor);
	useEffect(() => {
		cursorRef.current = data?.getGatherings?.cursor;
	}, [data?.getGatherings?.cursor]);

	const [fetchingMore, setFetchingMore, fetchingRef] = useStateRef<boolean>(false);
	const handleFetchMore = useStabilizedCallback(async () => {
		if (fetchingRef.current) return;
		if (cursorRef.current === "{}") return;
		setFetchingMore(true);
		try {
			const { data } = await fetchMore({
				variables: {
					input: {
						limit: LIMIT_FACTOR * numColumns,
						cursor: cursorRef.current,
						key,
					},
				},
			});
			cursorRef.current = data?.getGatherings?.cursor;
		} finally {
			setFetchingMore(false);
		}
	}, [fetchMore, fetchingRef, numColumns, setFetchingMore]);

	const [refreshing, setRefreshing, refreshingRef] = useStateRef<boolean>(false);
	const handleRefresh = useStabilizedCallback(async () => {
		if (refreshingRef.current) return;
		setRefreshing(true);
		try {
			await refetch({
				input: {
					limit: LIMIT_FACTOR * numColumns,
					key,
				},
			});
		} finally {
			setRefreshing(false);
		}
	}, [refreshingRef, setRefreshing, refetch, numColumns]);
	const isComplete = useMemo(() => {
		return data?.getGatherings?.cursor === "{}";
	}, [data?.getGatherings?.cursor]);
	const gatherings = useMemo(() => {
		return data?.getGatherings?.payload ?? [];
	}, [data?.getGatherings?.payload]);

	return {
		gatherings,
		fetchMore: handleFetchMore,
		refresh: handleRefresh,
		refreshing,
		loading: loading || fetchingMore,
		isComplete,
	};
};

export default useHomeGatherings;
