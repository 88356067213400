import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import GetContentFeedForHomeDocument from "src/api/graphql/__generated__/documents/GetContentFeedForHomeDocument";
import FeedItemVariant from "src/api/graphql/__generated__/enums/FeedItemVariant";
import PublicAssetTag from "src/api/graphql/__generated__/enums/PublicAssetTag";
import { GetContentFeedForHomeQuery } from "src/api/graphql/__generated__/graphql";
import { DAY_TO_MS } from "src/helpers/constants";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import MaxNumVisibleFeedItems from "../components/HomeFeedSection/constants/MaxNumVisibleFeedItems";

type AmplitudeEvent = {
	name: string;
	properties?: Record<string, any>;
};
interface HomeFeedItemBase {
	interactEvents: AmplitudeEvent[];
}
export interface HomeFeedPhotoType extends HomeFeedItemBase {
	variant: FeedItemVariant.Photo;
	photo: {
		photoId: string;
		gatheringId: string;
		readUrl: {
			v0TemplateUrl: string;
		};
	};
}
export interface HomeFeedPromotionType extends HomeFeedItemBase {
	variant: FeedItemVariant.Promotion;
	promotion: {
		backgroundImageUrl?: string;
		title: string;
		subTitle?: string;
		url?: string;
	};
}
export interface HomeFeedRecapType extends HomeFeedItemBase {
	variant: FeedItemVariant.Recap;
	recap: {
		recapId: string;
		imageUrl: string;
		preNavRoute?: string;
	};
}
export interface HomeFeedEmptyType extends HomeFeedItemBase {
	variant: "Empty";
	photo?: never;
}
const tryParse = (json: string | null | undefined) => {
	if (!json) return undefined;
	try {
		return JSON.parse(json);
	} catch (e) {
		return undefined;
	}
};
export type HomeFeedItemType =
	| HomeFeedPhotoType
	| HomeFeedEmptyType
	| HomeFeedPromotionType
	| HomeFeedRecapType;
const parseInteractEvent = (
	interactEvent?:
		| {
				eventName: string;
				eventPropertiesJson?: string | null | undefined;
		  }
		| null
		| undefined,
) => {
	if (!interactEvent) return undefined;
	const eventProperties = tryParse(interactEvent.eventPropertiesJson);
	return {
		name: interactEvent.eventName,
		properties: eventProperties as Record<string, any>,
	};
};
const mergeInteractEvents = (interactEvents?: (AmplitudeEvent | undefined)[]): AmplitudeEvent[] => {
	if (!interactEvents) return [];
	return interactEvents.filter((e): e is AmplitudeEvent => Boolean(e));
};
const parseQuery = (data: GetContentFeedForHomeQuery) => {
	const content: HomeFeedItemType[] = [];
	for (const item of data.getContentFeed?.payload ?? []) {
		if (item.variant === FeedItemVariant.Photo) {
			if (!item.photo) continue;
			if (!item.photo.gatheringId) continue;
			if (!item.photo.readUrl?.v0TemplateUrl) continue;
			content.push({
				variant: FeedItemVariant.Photo,
				interactEvents: mergeInteractEvents([
					parseInteractEvent(item.interactEvent),
					{
						name: "feed_photoInteract",
						properties: {
							photoId: item.photo.photoId,
							gatheringId: item.photo.gatheringId,
						},
					},
				]),
				photo: {
					photoId: item.photo.photoId,
					gatheringId: item.photo.gatheringId,
					readUrl: {
						v0TemplateUrl: item.photo.readUrl.v0TemplateUrl,
					},
				},
			});
		}
		if (item.variant === FeedItemVariant.Promotion) {
			if (!item.promotion) continue;
			content.push({
				variant: FeedItemVariant.Promotion,
				interactEvents: mergeInteractEvents([parseInteractEvent(item.interactEvent)]),
				promotion: {
					title: item.promotion.title,
					subTitle: item.promotion.subTitle ?? undefined,
					backgroundImageUrl: item.promotion.backgroundImageUrl ?? undefined,
					url: item.promotion.url ?? undefined,
				},
			});
		}
		if (item.variant === FeedItemVariant.Recap) {
			if (!item.recap) continue;
			content.push({
				variant: FeedItemVariant.Recap,
				interactEvents: mergeInteractEvents([
					parseInteractEvent(item.interactEvent),
					{
						name: "feed_recapInteract",
					},
				]),
				recap: {
					recapId: item.recap.recapId,
					imageUrl: item.recap.imageUrl,
					preNavRoute: item.recap.preNavRoute ?? undefined,
				},
			});
		}
		// Don't throw if the variant isn't recognized here
		// Backend might start returning arbitrary variants
	}
	return content;
};

const getCurEpochDay = () => {
	return Math.floor(getCurTime_MS() / DAY_TO_MS);
};

const useHomeFeed = () => {
	const { data, refetch, loading } = useQuery(GetContentFeedForHomeDocument, {
		fetchPolicy: "cache-only",
	});

	const [didRefetch, setDidRefetch] = useState(false);
	const { feed, shouldRefetch } = useMemo<{
		feed: HomeFeedItemType[];
		shouldRefetch?: boolean;
	}>(() => {
		if (!data) return { feed: [], shouldRefetch: true };
		const feed = parseQuery(data);
		const curDay = getCurEpochDay();
		// Most up-to-date, can return instantly
		if ((data?.getContentFeed?.payload ?? []).some((item) => item.epochDay === curDay)) {
			return {
				feed,
			};
		}
		if (didRefetch) {
			return {
				feed,
			};
		} else {
			return {
				feed,
				shouldRefetch: true,
			};
		}
	}, [data, didRefetch]);
	useEffect(() => {
		if (shouldRefetch) {
			refetch().finally(() => {
				setDidRefetch(true);
			});
		}
	}, [refetch, shouldRefetch]);

	const feedLoading = useMemo<boolean>(() => {
		return loading || Boolean(shouldRefetch);
	}, [loading, shouldRefetch]);

	const filledFeed = useMemo(() => {
		const filledFeed: HomeFeedItemType[] = [...feed];
		if (feedLoading) return filledFeed;
		const feedLen = filledFeed.length;
		for (let i = 0; i < MaxNumVisibleFeedItems - feedLen; i++) {
			const placeholderItem = HomeFeedPlaceholders[i % HomeFeedPlaceholders.length];
			if (placeholderItem) {
				filledFeed.push(placeholderItem);
			}
		}
		return filledFeed;
	}, [feed, feedLoading]);

	return {
		loading: feedLoading,
		feed: filledFeed,
		refresh: refetch,
	};
};

const DefaultPromotionInteractEvent = "feed_defaultCreatePromotionInteract";
const UrlBase = "https://dnnqo5iczq5wf.cloudfront.net/cache/image";
const HomeFeedPlaceholders: HomeFeedItemType[] = [
	{
		variant: FeedItemVariant.Promotion,
		promotion: {
			title: "Memories",
			subTitle: "Add photos to unlock",
			url: "/create",
			backgroundImageUrl: UrlBase + "/fe0d34138e/v0/lg/null/inside/png",
		},
		interactEvents: [
			{
				name: DefaultPromotionInteractEvent,
				properties: {
					variant: "memories",
				},
			},
		],
	},
	// I don't want to bundle these images into the app, so they're just using the remote asset URL generated for app clip
	{
		variant: FeedItemVariant.Promotion,

		promotion: {
			title: "Night out",
			subTitle: "For the blurry and dazed photos",
			url: `/create?template=${PublicAssetTag.NightOut}&name=Night%20out`,
			backgroundImageUrl: UrlBase + "/ed0f0c8dfd/v0/lg/null/inside/png",
		},
		interactEvents: [
			{
				name: DefaultPromotionInteractEvent,
				properties: {
					variant: PublicAssetTag.NightOut,
				},
			},
		],
	},
	{
		variant: FeedItemVariant.Promotion,
		promotion: {
			title: "Concert",
			subTitle: "For the fan girls and bts dumps",
			url: `/create?template=${PublicAssetTag.Concert}&name=Concert`,
			backgroundImageUrl: UrlBase + "/2ab00f3787/v0/lg/null/inside/png",
		},
		interactEvents: [
			{
				name: DefaultPromotionInteractEvent,
				properties: {
					variant: PublicAssetTag.Concert,
				},
			},
		],
	},
	{
		variant: FeedItemVariant.Promotion,
		promotion: {
			title: "Formals",
			subTitle: "For the glitz and glam digis",
			url: `/create?template=${PublicAssetTag.Formals}&name=Formals`,
			backgroundImageUrl: UrlBase + "/4f85026053/v0/lg/null/inside/png",
		},
		interactEvents: [
			{
				name: DefaultPromotionInteractEvent,
				properties: {
					variant: PublicAssetTag.Formals,
				},
			},
		],
	},
	{
		variant: FeedItemVariant.Promotion,
		promotion: {
			title: "Trip",
			subTitle: "For the wanderlust and travel bugs",
			url: `/create?template=${PublicAssetTag.Trip}&name=Trip`,
			backgroundImageUrl: UrlBase + "/56e7026507/v0/lg/null/inside/png",
		},
		interactEvents: [
			{
				name: DefaultPromotionInteractEvent,
				properties: {
					variant: PublicAssetTag.Trip,
				},
			},
		],
	},
];
export default useHomeFeed;
