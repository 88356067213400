enum PublicAssetTag {
  Beach = 'Beach',
  BidDay = 'BidDay',
  Birthday = 'Birthday',
  Brunch = 'Brunch',
  Concert = 'Concert',
  Formals = 'Formals',
  Fraternity = 'Fraternity',
  Friends = 'Friends',
  GameDay = 'GameDay',
  Girlies = 'Girlies',
  Graduation = 'Graduation',
  NightOut = 'NightOut',
  Picnic = 'Picnic',
  Pregame = 'Pregame',
  Rave = 'Rave',
  Sorority = 'Sorority',
  Summer24 = 'Summer24',
  Test = 'Test',
  Trip = 'Trip',
  Wedding = 'Wedding'
}
export default PublicAssetTag
