import { useEffect, type EffectCallback } from "react";

/**
 * Cross-platform version of `useFocusEffect`. Make sure you wrap the callback in `useCallback`
 */
const useAdaptedFocusEffect = (callbackFn: EffectCallback) => {
	useEffect(() => {
		return callbackFn();
	}, [callbackFn]);
};
export default useAdaptedFocusEffect;
